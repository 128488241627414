import React, {useState} from 'react';
import styled from 'styled-components';
import Layout from "../components/layout";
import SectionFooter from '../components/sections/section-footer/SectionFooter';
import { theme } from "../utils/theme";
import { navigate } from 'gatsby-link';

import { Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { encode } from '../utils/util';



const Container = styled.div`
  /* height: 100vh; */
  width: 100%;
  background-color: ${props => props.theme.colors.dark};
  overflow-y: visible;
`

const Top = styled.div`
  width: 70%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;

  @media ${props => props.theme.breaks.down('md')} {
    width: 90%;
    justify-content: flex-start;
  }
`

const TitleCond = styled.h5`
  font-size: 3rem;
  font-weight: 300;
  width: 300px;
  line-height: 1.1;
  color: ${props => props.theme.colors.body};
  margin-bottom: 80px;
  text-align: left;

  @media ${props => props.theme.breaks.down('md')} {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
`

const MainSection = styled.div`
  height: 100vh;
  padding-top: 50px;
  text-align: center;
  padding-left: 80px;

  .bottom-form {
    margin-top: 15px;
  }

  @media ${props => props.theme.breaks.down('md')} {
    padding-left: 0;
  }
`

const Form = styled.form`
  width: 60%;
  margin: 0 auto;
  color: ${props => props.theme.colors.body};

  & * {
  color: ${props => props.theme.colors.body};

  }

  .MuiFormLabel-root {
  color: ${props => props.theme.colors.body};
  }

  .MuiInputBase-input {
    border-bottom: 2px solid ${props => props.theme.colors.body};
    color: ${props => props.theme.colors.primary};
  }

  textarea.MuiInputBase-input {
    border: none;
  }

  @media ${props => props.theme.breaks.down('md')} {
    width: 90%;
  }
`

const Button = styled.button`
  display: block;
  text-align: center;
  background-color: ${props => props.theme.colors.body};
  color: ${props => props.theme.colors.dark};
  width: 96%;
  border: none;
  padding: 15px 0;
  margin: 0 auto;
  margin-top: 80px;
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.colors.bodyLight};
  }
`

const FixedFooter = styled.div`
  width: 100%;
  /* min-width: ${props => props.theme.maxWidthMd};
  max-width: 1400px; */
  position: fixed;
  /* left: 50%;
  transform: translateX(-50%); */
  height: 100px;
  padding-left: 40px;
  bottom: 20px;
  z-index: 1;

  @media ${props => props.theme.breaks.down('md')} {
    padding-left: 0;
    bottom: 0;
  }
`


const PainFormPage = ({ data, location }) => {

  // console.log(location.state);
  const [state, setState] = useState({
    wrong: location?.state?.pain || ''
  });

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  }

  return (
    
      <Layout color={theme.colors.body} pageTitle="Pain form">
      <Container>
        
        <MainSection className="point-sec">
          <Top>
            <TitleCond>Do you want to complain about what’s going on with your business?</TitleCond>
          </Top>
          <Form
            name="pain-form"
            method="post"
            action="/submitted"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={handleSubmit}
            >
              <input type="hidden" name="form-name" value="pain-form" />
              <p hidden>
                <label>
                  Don’t fill this out: <input name="bot-field" onChange={handleChange} />
                </label>
              </p>

            <Grid container spacing={5}>
              <Grid item xs={12} md={4}>

                <TextField fullWidth name="name" label="Your name" placeholder="Your name" color="secondary" value={state.name} onChange={handleChange} required></TextField>

              </Grid>
              <Grid item xs={12} md={4}>
                
                <TextField fullWidth name="company" label="Your company" placeholder="Your company" color="secondary" value={state.company} onChange={handleChange} required></TextField>
                
              </Grid>
              <Grid item xs={12} md={4}>
                
                <TextField fullWidth name="email" label="Your email" placeholder="Your email" type="email" color="secondary" value={state.email} onChange={handleChange} required></TextField>
                
              </Grid>
            </Grid>

            <Grid className="bottom-form" container spacing={5}>
              <Grid item xs={12} md={4}>
                
                <TextField fullWidth name="wrong" label="What’s wrong with your business?" placeholder="What’s wrong with your business?" value={state.wrong} onChange={handleChange} color="secondary"></TextField>
                
              </Grid>
              <Grid item xs={12} md={4}>
                
                <TextField fullWidth name="help" label="How can we help?" placeholder="How can we help?" value={state.help} onChange={handleChange} color="secondary"></TextField>
                
              </Grid>
            </Grid>

            <Grid container spacing={5}>
              <Button>Submit</Button>
            </Grid>
          </Form>
          
        </MainSection>

         <FixedFooter>
          <SectionFooter color={theme.colors.body}></SectionFooter>
          
         </FixedFooter>
        
      </Container>
      </Layout>
    
)
}

export default PainFormPage;